<template>
  <div class="flex flex-col">
    <div
      v-if="withTitle"
      class="flex flex-row items-center">
      <span class="text-lg font-bold">Crear proveedor</span>
      <div class="ml-auto">
        <sa-icon-button
          with-bg
          iconClass="text-xl bx bx-x"
          @click="cancel" />
      </div>
    </div>
    <div class="py-2">
      <el-form
        :model="supplier"
        :rules="formRules"
        ref="supplierForm"
        v-loading="loadingSavingSupplier">
        <el-form-item prop="company">
          <span>Empresa</span>
          <el-input v-model="supplier.company"/>
        </el-form-item>
        <el-form-item prop="webpage">
          <span>Sitio web</span>
          <el-input v-model="supplier.webpage" />
        </el-form-item>
        <el-form-item prop="contactFullName">
          <span>Nombre del contacto</span>
          <el-input v-model="supplier.contactFullName" />
        </el-form-item>
        <el-form-item prop="contactEmail">
          <span>E-mail de contacto</span>
          <el-input v-model="supplier.contactEmail" />
        </el-form-item>
        <el-form-item prop="phone">
          <span>Teléfono de contacto</span>
          <sa-phone-country :model="supplier" />
        </el-form-item>
        <el-form-item prop="contactDescription">
          <span>Detalles de contacto</span>
          <el-input type="textarea" v-model="supplier.contactDescription" />
        </el-form-item>
      </el-form>
    </div>
    <div v-if="errorMessage">
      <span class="text-red-500">{{ errorMessage }}</span>
    </div>
    <div class="flex justify-end flex-row space-x-4">
        <sa-button type="secondary"
          @click="cancel">
          Cancelar
        </sa-button>
        <sa-button
          type="primary"
          @click="save"
          v-loading="loadingSavingSupplier">
          Guardar
        </sa-button>
    </div>
  </div>
</template>
<script>
import { ContactTypeEnum } from '@/constants';
import SupplierService from '@/services/SupplierService';
import error from '@/mixins/error';

export default {
  name: 'SupplierForm',
  props: {
    supplier: {
      type: Object,
      default: () => ({
        id: null,
        company: null,
        webpage: null,
        contactFullName: null,
        contactEmail: null,
        phone: null,
        contactDescription: null,
        sendEmailNotifications: false,
        phoneCountryCodeId: null,
      }),
    },
    withTitle: {
      type: Boolean,
      default: () => false
    },
  },
  mixins: [
    error,
  ],
  data() {
    return {
      formRules: {
        company: [
          { required: true, message: 'Captura el nombre de la empresa' },
        ],
         webpage: [
          { required: false, message: 'Captura el sitio web de la empresa' },
        ],
        contactFullName: [
          { required: false, message: 'Captura nombre y apellido de la persona de contacto' },
        ],
        contactEmail: [
          { required: false, message: 'Captura un email de contacto' },
          { type: 'email', message: 'Email inválido', trigger: ['blur', 'change'] },
        ],
        phone: [
          { required: true, message: 'Captura un teléfono de contacto' },
        ],
        contactDescription: [
          { required: false, message: 'Descripción del proveedor' },
        ],
        phoneCountryCodeId: [{ required: true, message: "Captura el Código de Area del cliente" }],
      },
      errorMessage: null,
      loadingSavingSupplier: false,
      contactTypes: [],
    };
  },
  created() {
    this.contactTypes = this.loadContactTypes();
  },
  methods: {
    cancel() {
      this.$emit('onCancel');
    },
    async save() {
      this.$refs['supplierForm'].validate(async isFormValid => {
        if (!isFormValid)
          return;
        await this.saveSupplier();
      });
    },
    async saveSupplier() {
      try {
        this.loadingSavingSupplier = true;
        const savedSupplier = !this.supplier.id
          ? await SupplierService.create({
            ...this.supplier,
            contactType: ContactTypeEnum.Phone,
            contactEmail: this.supplier.contactEmail?.toLowerCase() || '',
          })
          : await SupplierService.update(this.supplier.id, {
            ...this.supplier,
            contactType: ContactTypeEnum.Phone,
            contactEmail: this.supplier.contactEmail?.toLowerCase() || '',
          });
        this.$emit('onSaved', savedSupplier);
      }
      catch(error) {
        this.errorMessage = this.getErrorMessage(error);
        this.loadingSavingSupplier = false;
      }
    },
    loadContactTypes() {
      return [
        { id: ContactTypeEnum.Phone, label: 'Teléfono' },
        { id: ContactTypeEnum.Whatsapp, label: 'Whatsapp' },
        { id: ContactTypeEnum.Email, label: 'Email' },
        { id: ContactTypeEnum.Other, label: 'Otro' },
      ];
    },
  },
}
</script>
